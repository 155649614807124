import { useEffect, useState, useMemo, Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import * as MUI from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/x-data-grid';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import api from './api';
import Toolbar from './Toolbar';
import Sidebar from './Sidebar';
import LoginForm from './LoginForm';

import { GlobalContext } from './GlobalContext';
import { NetworkContextProvider } from './network/Context';

const Billing = lazy(() => import('./billing/'));
const Core = lazy(() => import('./core/'));
const Dashboard = lazy(() => import('./dashboard/'));
const Network = lazy(() => import('./network/'));
const HRM = lazy(() => import('./hrm/'));
const AdminManual = lazy(() => import('./manual/admin/'));

const drawerWidth = 240;

export default function App() {
	const location = useLocation();
	const navigate = useNavigate();
	const [globalContext, setGlobalContext] = useState({
		brand: 'MultiAdmin',
		experimental: false,
		modules: [],
	});
	const [account, setAccount] = useState(false);
	const [error, setError] = useState(false);
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [themeMode, setThemeMode] = useState('system');

	api.showError = (err) => {
		setError(err)
	}

	const start = async (startParams = {}) => {
		const needLoginForm = showLoginForm;
		try {
			setShowLoginForm(false);
			setAccount(false);

			if (window.location.pathname === '/user/sso') {
				startParams.callback = window.location.href;
			}

			let config = await api.call('login', startParams);
			if (config.challenge) {
				if (config.challenge.authType === 'oidc') {
					window.location = config.challenge.redirect;
				}
				if (config.challenge.authType === 'password') {
					setShowLoginForm(true);
				}
			}
			if (config.token) {
				// Successfully logged in
				api.setToken(config.token);
				setAccount(config.account);
			}
			if (config.config) {
				config.config.brand = config.config.brand || 'MultiAdmin';
				setGlobalContext(config.config);
			}
		} catch(e) {
			setShowLoginForm(needLoginForm);
		}
	}

	useEffect(() => {
		if (!account) {
			const mode = localStorage.getItem('ma-mode') || 'system';
			setThemeMode(mode);
			start();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const theme = useMemo(() => {
		let mode = themeMode;
		if (mode === 'system') {
			var mql = window.matchMedia('(prefers-color-scheme: dark)');
			mode = mql.matches ? 'dark' : 'light';
		}
		return createTheme({
			palette: {
				mode: mode
			}
		}, ruRU);
	}, [themeMode]);

	const setLightMode = (mode) => {
		setThemeMode(mode);
		localStorage.setItem('ma-mode', mode);
	}

	const atleastMedium = MUI.useMediaQuery(theme.breakpoints.up('md'));

	return (
		<ThemeProvider theme={theme}>
		<GlobalContext.Provider value={globalContext}>
		<NetworkContextProvider>

		<MUI.Box sx={{ display: 'flex' }}>
		<MUI.CssBaseline />
		{account && (<>
			<MUI.AppBar position="fixed">
				<Toolbar account={account} themeMode={themeMode} setThemeMode={setLightMode} />
			</MUI.AppBar>
			<MUI.Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
				<MUI.Drawer
					variant={atleastMedium ? "permanent" : "temporary"}
					sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex: 1000 } }}
					open={location.search === '?leftMenu'}
					onClose={() => navigate(-1)}
					ModalProps={{ keepMounted: true }}
				>
					<Sidebar />
				</MUI.Drawer>
			</MUI.Box>
			<MUI.Box component="main"
				sx={{ flexGrow: 1, width: { md: `calc(100% - ${drawerWidth}px)` }, minHeight: '100vh', display: 'flex', flexDirection: 'column' }} >
				<MUI.Toolbar sx={{ mb: 0.5 }} />
				<Suspense fallback={
					<MUI.Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
						<MUI.CircularProgress />
					</MUI.Box>
				}>
					<Routes>
						<Route exact path="/" element={<Dashboard />} />
						<Route path="/billing/*" element={<Billing />} />
						<Route path="/core/*" element={<Core />} />
						<Route path="/network/*" element={<Network />} />
						<Route path="/hrm/*" element={<HRM />} />
						<Route path="/manual/admin/*" element={<AdminManual />} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</Suspense>
			</MUI.Box>
		</>)}
		{showLoginForm && (<LoginForm start={start} />)}
		<MUI.Snackbar open={error !== false} autoHideDuration={6000} onClose={() => setError(false)}>
			<MUI.Alert severity="error" variant="filled">{error}</MUI.Alert>
		</MUI.Snackbar>
	</MUI.Box>

	</NetworkContextProvider>
	</GlobalContext.Provider>
	</ThemeProvider>
	);
}
