import { useState, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as MUI from '@mui/material';

import IconApps from '@mui/icons-material/Apps';
import IconApproval from '@mui/icons-material/Approval';
import IconBadge from '@mui/icons-material/Badge';
import IconCalendar from '@mui/icons-material/CalendarMonth';
import IconExpand from '@mui/icons-material/ExpandMore';
import IconHandyman from '@mui/icons-material/Handyman';
import IconHelpCenter from '@mui/icons-material/HelpCenter';
import IconHome from '@mui/icons-material/Home';
import IconList from '@mui/icons-material/ListAlt';
import IconLan from '@mui/icons-material/Lan';
import IconManageAccounts from '@mui/icons-material/ManageAccounts';
import IconPin from '@mui/icons-material/Pin';
import IconPing from '@mui/icons-material/NetworkPing';
import IconPolicy from '@mui/icons-material/Policy';
import IconRouter from '@mui/icons-material/Router';
import IconStorage from '@mui/icons-material/Storage';
import IconWidgets from '@mui/icons-material/Widgets';

import globalConfig from './config.json';
import { GlobalContext } from './GlobalContext';

const MenuItem = (props) => (
	<MUI.ListItem button onClick={props.onClick} disabled={props.disabled}>
		<MUI.ListItemIcon sx={{ minWidth: '32px' }}>{props.icon}</MUI.ListItemIcon>
		<MUI.ListItemText primary={props.name} />
	</MUI.ListItem>
);

export default function Sidebar() {
	const location = useLocation();
	const navigate = useNavigate();
	const globalContext = useContext(GlobalContext);
	const [expandNetwork, setExpandNetwork] = useState(true);
	const [expandNetconf, setExpandNetconf] = useState(false);
	const [expandAccess, setExpandAccess] = useState(false);
	const [expandSettings, setExpandSettings] = useState(false);
	const [expandStaff, setExpandStaff] = useState(false);

	const go = (url) => {
		navigate(url, {
			state: location.state,
			replace: location.search === '?leftMenu'
		});
	}

	const modules = useMemo(() => {
		const res = {};
		const moduleList = globalContext.modules || [];
		moduleList.forEach(element => {
			res[element.name] = true;
		});
		return res;
	}, [globalContext]);

	return (<>
		<MUI.Toolbar />
		<MUI.Divider />
		<MUI.List sx={{ flex: 1 }}>
			<MenuItem onClick={() => go("/")} name="Главная" icon={(<IconHome />)} />

			{modules['network'] &&
			<MUI.Accordion expanded={expandNetwork} onChange={() => setExpandNetwork(!expandNetwork)}>
				<MUI.AccordionSummary expandIcon={<IconExpand />}>
					Инфраструктура сети
				</MUI.AccordionSummary>
				<MUI.AccordionDetails sx={{ p: 0 }}>
					<MUI.Divider />
					<MenuItem onClick={() => go("/network/map")} name="Карта сети" icon={(<IconLan />)} />
					<MenuItem onClick={() => go("/network/box")} name="Оборудование" icon={(<IconStorage />)} />
					<MenuItem onClick={() => go("/network/dict")} name="Справочники" icon={(<IconList />)} />
					<MenuItem onClick={() => go("/network/otdr")} name="Рефлектограммы" icon={(<IconPing />)} />
				</MUI.AccordionDetails>
			</MUI.Accordion>
			}

			{modules['netconf'] &&
			<MUI.Accordion expanded={expandNetconf} onChange={() => setExpandNetconf(!expandNetconf)}>
				<MUI.AccordionSummary expandIcon={<IconExpand />}>
					Конфигурация сети
				</MUI.AccordionSummary>
				<MUI.AccordionDetails sx={{ p: 0 }}>
					<MUI.Divider />
					<MenuItem onClick={() => go("/netconf/services")} name="Сервисы" icon={(<IconWidgets />)} disabled />
					<MenuItem onClick={() => go("/netconf/ipam")} name="Ресурсы" icon={(<IconPin />)} disabled />
					<MenuItem onClick={() => go("/netconf/devices")} name="Оборудование" icon={(<IconStorage />)} disabled />
					<MenuItem onClick={() => go("/netconf/cpe")} name="CPE" icon={(<IconRouter />)} disabled />
				</MUI.AccordionDetails>
			</MUI.Accordion>
			}

			<MUI.Accordion expanded={expandSettings} onChange={() => setExpandSettings(!expandSettings)}>
				<MUI.AccordionSummary expandIcon={<IconExpand />}>
					Настройки
				</MUI.AccordionSummary>
				<MUI.AccordionDetails sx={{ p: 0 }}>
					<MUI.Divider />
					<MenuItem onClick={() => go("/core/modules")} name="Модули" icon={(<IconApps />)} />
					<MenuItem onClick={() => go("/core/agents")} name="Агенты" icon={(<IconHandyman />)} />
					<MenuItem onClick={() => go("/manual/admin")} name="Документация" icon={(<IconHelpCenter />)} />
				</MUI.AccordionDetails>
			</MUI.Accordion>

			{modules['netconf'] &&
			<MUI.Accordion expanded={expandStaff} onChange={() => setExpandStaff(!expandStaff)}>
				<MUI.AccordionSummary expandIcon={<IconExpand />}>
					Персонал
				</MUI.AccordionSummary>
				<MUI.AccordionDetails sx={{ p: 0 }}>
					<MUI.Divider />
					<MenuItem onClick={() => go("/hrm/docs")} name="Документы" icon={(<IconApproval />)} />
					<MenuItem onClick={() => go("/hrm/schedule")} name="График работы" icon={(<IconCalendar />)} disabled />
					<MenuItem onClick={() => go("/hrm/users")} name="Сотрудники" icon={(<IconBadge />)} disabled />
				</MUI.AccordionDetails>
			</MUI.Accordion>
			}

			<MUI.Accordion expanded={expandAccess} onChange={() => setExpandAccess(!expandAccess)}>
				<MUI.AccordionSummary expandIcon={<IconExpand />}>
					Права доступа
				</MUI.AccordionSummary>
				<MUI.AccordionDetails sx={{ p: 0 }}>
					<MUI.Divider />
					<MenuItem onClick={() => go("/core/users")} name="Пользователи" icon={(<IconBadge />)} />
					<MenuItem onClick={() => go("/core/policy")} name="Политики" icon={(<IconPolicy />)} />
					<MenuItem onClick={() => go("/core/roles")} name="Роли" icon={(<IconManageAccounts />)} />
				</MUI.AccordionDetails>
			</MUI.Accordion>
		</MUI.List>
		<MUI.Divider />
		<MUI.Button
			sx={{ textTransform: 'none' }}
			component="a"
			href="https://cubitel.org/multiadmin/"
			target="_blank"
		>
			MultiAdmin v{globalConfig.version}
		</MUI.Button>
	</>);
}
